<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Transaksi Invoice </h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">&nbsp;</label>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>                        

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Status Payment</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <v-select id="StatusPayment" class="pb-3" :options="StatusPaymentData" v-model="StatusPayment" @input="onChangeStatusPayment()"/>
                                <label id="errorStatusPayment" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol> 
                        </CRow>
                    </CCol>

                    <!-- Quantity Per Status-->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Quantity Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Sent</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{SentQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteQty}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                    
                    <!-- Total Vat Per Status -->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Total Price Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol>
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Send</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{SentTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteTotal}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                        &nbsp;
                        <button id="view" class="btn btn-secondary"
                            @click="generatePDF()">
                            <i class="fa fa-file-pdf-o"></i> PDF
                        </button>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <transaction-invoice-report-grid ref = "transactionInvoiceReportGrid" 
                            :key="gridReload" 
                            :dateTo="DateToChangest" 
                            :dateFrom="DateFromChangest" 
                            :statusPayment="StatusPayment"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import transactionInvoiceReportGrid from '../TransactionInvoiceReport/Grid/TransactionInvoiceReportGrid.vue'
import transactionInvoiceReportService from '../TransactionInvoiceReport/Script/TransactionInvoiceReportService.js'
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { report, datetime_zone } from '../../../infrastructure/constant/variable';

export default {
    name: 'TransactionInvoiceReport',
    components: {
        'transaction-invoice-report-grid': transactionInvoiceReportGrid,
        'date-picker': datePicker
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();

        var variables = {
            startDate: this.DateFrom + datetime_zone.date_from,
            endDate: this.DateTo + datetime_zone.date_to,
        };
        this.getQuantity(variables)
        
        this.StatusPaymentData = [
            {value: 'PAID', label: 'Paid'},
            {value: 'UNPAID', label: 'Unpaid'},
        ];
        this.StatusPayment = null;
    },
   
    data(){
        return{
            gridReload: 0,
            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,
            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            NewQty: '',
            SentQty:'',
            DeleteQty: '',

            NewTotal: '',
            SentTotal: '',
            DeleteTotal: '',

            Error: 0,
            
            DateTypeData: [],
            DateType: report.default_date,
            
            StatusPaymentData: [],
            StatusPayment: '',
        }
    },
    methods: {
        async getQuantity(variables){
            var dataInvoiceReportSummary = await transactionInvoiceReportService.getTransactionInvoiceReport(variables);
            var summaryQuantity = dataInvoiceReportSummary.summary.quantity_per_status;
            var summaryTotal = dataInvoiceReportSummary.summary.total_price_per_status;

            this.NewQty = summaryQuantity.new;
            this.SentQty = summaryQuantity.sent;
            this.DeleteQty = summaryQuantity.delete;

            this.NewTotal = kendo.format('{0:N0}', summaryTotal.new);
            this.SentTotal = kendo.format('{0:N0}', summaryTotal.sent);
            this.DeleteTotal = kendo.format('{0:N0}', summaryTotal.delete);
        },
        async onChangeStatusPayment(){
            if(this.StatusPayment == '') {
                this.StatusPayment = null;
            }
            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;

            var variables = {
                startDate: this.DateFrom + datetime_zone.date_from,
                endDate: this.DateTo + datetime_zone.date_to,
            };
            await this.getQuantity(variables);
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables)
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.transactionInvoiceReportGrid.exportExcel();
        },
        generatePDF(){
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.transactionInvoiceReportGrid.generatePDF();
            //     }
            //     else if (result.isDenied) {
            const routeData = this.$router.resolve({name: 'Transaction Invoice Report Html Template', params: { startdate: this.DateFrom, enddate: this.DateTo }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        },
    }
}
</script>

<style scoped>

</style>